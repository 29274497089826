import React from "react";
import { Link as ScrollLink } from "react-scroll";
import tw from "twin.macro";
import styled, { createGlobalStyle, keyframes } from "styled-components";
import { css } from "styled-components/macro"; // eslint-disable-line
import { motion } from "framer-motion";

import logo from "../../images/logo-logistics-mini.svg";

import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";

// Анимация пульсации для кнопок
const pulsateAnimation = keyframes`
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
`;

// Контейнер для хедера без теней
const HeaderContainer = tw.header`
  flex flex-wrap justify-between items-center w-full
  mx-auto relative z-10
  px-4 bg-white
`;

// Глобальные стили
const GlobalStyles = createGlobalStyle`
    @font-face {
        font-family: 'SFUIText';
        src: url('../../fonts/SFUIDisplay-Ultralight.woff') format('woff');
        font-style: italic;
        font-weight: normal;
    }
`;

// Стиль для навигационных ссылок
const NavLinks = tw.div`inline-block mr-24`;

const NavLink = styled(ScrollLink)`
    font-family: 'SFUIText', sans-serif;
    ${tw`text-3xl my-2 lg:text-lg lg:mx-4 lg:my-0 font-semibold tracking-wide transition duration-300 pb-1`}
    color: #000000;
    text-decoration: none;

    &:hover, &:focus {
        color: #0ABD19;
        transform: scale(1.1); /* Добавлен эффект увеличения при наведении */
        border-bottom: 2px solid #0ABD19; /* Добавлен нижний бордер при наведении */
    }
`;

// Основная кнопка без теней
const PrimaryLink = styled(NavLink)`
    ${tw`lg:mx-0
    px-8 py-3 rounded text-gray-100
    hocus:text-gray-200 focus:shadow-outline rounded-full
    border-b-0`}
    background-color: #0ABD19;
    transition: background-color 0.3s ease;

    &:not(:hover):not(:focus) {
        animation: ${pulsateAnimation} 1.5s infinite alternate;
    }

    &:hover, &:focus {
        background-color: #0ABD50;
        transform: scale(1.1); /* Увеличение при наведении */
    }
`;

// Логотип без теней
const LogoLink = styled.a`
    ${tw`flex items-center font-black border-b-0 mt-2`}
`;

const StyledLogo = styled.img`
    ${tw`w-40 h-10`}
    width: ${({ width }) => width || 'auto'};
    height: ${({ height }) => height || 'auto'};
`;

// Контейнер для мобильной навигации
const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`;

const MobileNavLinks = motion(styled.div`
    ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-white`}
    ${NavLinks} {
        ${tw`flex flex-col items-center`}
    }
`);

// Настройка для десктопной навигации
const DesktopNavLinks = styled.nav`
    ${tw`hidden lg:flex flex-1 justify-between items-center`}
    margin-right: 5%; /* Уменьшение отступа справа */

    @media (max-width: 1500px) {
        margin-right: 2%; /* Уменьшение отступа для экранов меньше 1500px */
    }
`;

// Информация о контактах с уменьшенным шрифтом и отступами
const InfoContact = styled.div`
    ${tw`hidden lg:flex items-center text-xl font-semibold tracking-wide text-black mr-8`} /* Уменьшение шрифта и отступов */
    @media (max-width: 1500px) {
    font-size: 1rem; /* Уменьшение размера шрифта для экранов меньше 1500px */
    margin-right: 4px; /* Уменьшение отступа справа */
}
`;

// Номер телефона с аналогичными изменениями
const PhoneNumber = styled.div`
    ${tw`hidden lg:flex items-center text-xl font-semibold tracking-wide text-black`} /* Уменьшение шрифта */
    @media (max-width: 1500px) {
    font-size: 1rem; /* Уменьшение размера шрифта для экранов меньше 1500px */
    margin-left: 4px; /* Добавление небольшого отступа слева */
}
`;

const collapseBreakPointCssMap = {
    sm: {
        mobileNavLinks: tw`sm:hidden`,
        desktopNavLinks: tw`sm:flex`,
        mobileNavLinksContainer: tw`sm:hidden`
    },
    md: {
        mobileNavLinks: tw`md:hidden`,
        desktopNavLinks: tw`md:flex`,
        mobileNavLinksContainer: tw`md:hidden`
    },
    lg: {
        mobileNavLinks: tw`lg:hidden`,
        desktopNavLinks: tw`lg:flex`,
        mobileNavLinksContainer: tw`lg:hidden`
    },
    xl: {
        mobileNavLinks: tw`xl:hidden`,
        desktopNavLinks: tw`xl:flex`,
        mobileNavLinksContainer: tw`xl:hidden`
    }
};

export default ({ roundedHeaderButton = false, logoLink, links, className, collapseBreakpointClass = "lg" }) => {
    const defaultLinks = [
        <NavLinks key={1}>
            <NavLink to="home" smooth={true} duration={500}>Главная</NavLink>
            <NavLink to="about" smooth={true} duration={500}>О нас</NavLink>
            <NavLink to="how-it-works" smooth={true} duration={500}>Как это работает</NavLink>
            <NavLink to="goal" smooth={true} duration={500}>Наша цель</NavLink>
            <NavLink to="contacts" smooth={true} duration={500}>Контакты</NavLink>
        </NavLinks>
    ];

    const collapseBreakpointCss = collapseBreakPointCssMap[collapseBreakpointClass];

    const defaultLogoLink = (
        <LogoLink href="/">
            <StyledLogo src={logo} alt="logo" width="auto" height="auto" />
        </LogoLink>
    );

    logoLink = logoLink || defaultLogoLink;
    links = links || defaultLinks;

    return (
        <HeaderContainer className={className || "header-light"}>
            <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
                <div tw="flex items-center">
                    {logoLink}
                </div>
                <div tw="flex items-center ml-8">
                    {links}
                    <InfoContact>
                        info.eparcel-logistics@yandex.ru
                    </InfoContact>
                    <PhoneNumber>
                        +7 964 771 22 16
                    </PhoneNumber>
                </div>
            </DesktopNavLinks>

            <MobileNavLinksContainer css={collapseBreakpointCss.mobileNavLinksContainer}>
                {logoLink}
                <MobileNavLinks initial={{ x: "150%", display: "none" }} css={collapseBreakpointCss.mobileNavLinks}>
                    {links}
                </MobileNavLinks>
            </MobileNavLinksContainer>
        </HeaderContainer>
    );
};
